import Scroll from "./component/scroll/scroll.js";
import Box from "@mui/material/Box";
import useStyles from "./styles.js";
function App() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.backColor}>
        <Scroll />
      </Box>
    </>
  );
}

export default App;
