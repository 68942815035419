import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  topBarContainer: {
    padding: 0,
    backgroundColor: "#FAF6F2",
    display: "flex",
    justifyContent: "space-around",
    paddingTop: "30px",
    height: "100px",
  },
  topContainer: {
    opacity: ".96",
  },
  logoInside: {
    marginLeft: "0 auto",
  },
  logoButton: {
    marginLeft: "20px",
    paddingBottom: "20px",
    "@media (max-width: 600px)": {
      marginLeft: "0",
    },
  },
}));
