import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useStyles from "./styles.js";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Manicure() {
  const classes = useStyles();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              Acrylic
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              Acrylic nails is a form of nail enhancements made by combining a
              liquid acrylic product with a powdered acrylic. It is the most
              popular form of nail enhancements that is, typically, recommended
              for those with naturally weak and brittle nails.
              <Box
                sx={{
                  textAlign: "left",
                  margin: " 0px auto",
                }}
                width={{ xs: "100%", md: 500 }}
                padding={{ xs: "15px", md: "none" }}
              >
                <br />
                <div className={classes.priceLine}>
                  <span>
                    <b>Color Tip</b>
                  </span>
                  <span></span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b>White Tip </b>
                  </span>
                  <span></span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b>Full Set(Acrylic) </b>
                  </span>
                  <span></span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b>Fill-ins(Acrylic)</b>
                  </span>
                  <span></span>
                </div>
                <div className={classes.priceLine}>
                  <b>*Additional for Coffin, Almond Shape and Long Nails</b>
                </div>
              </Box>
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              Solar Pink & White
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              Solar nails are your typical acrylic nails but pink and white in
              color to achieve a classic, clean, natural, and shiny look. They
              can look similarly to French manicured nails. Done with a
              non-yellowing formula, it will never turn yellow under the sun or
              tanning bed.
              <Box
                sx={{
                  textAlign: "left",
                  margin: " 0px auto",
                }}
                width={{ xs: "100%", md: 500 }}
                padding={{ xs: "15px", md: "none" }}
              >
                <br />
                <div className={classes.priceLine}>
                  <span>
                    {" "}
                    <b>Full Set Solar Color tip</b>
                  </span>
                  <span></span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b>Full Set(Pink & White)</b>
                  </span>
                  <span></span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b>Fill-ins(Pink & White)</b>
                  </span>
                  <span></span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b>Pink Fill Only</b>
                  </span>
                  <span></span>
                </div>
              </Box>
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              Dipping Powder
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              Dip powder is a good alternative to nail enhancement that
              typically involves dipping the nail into colored powder or
              brushing the dip powder onto the nail, and then using a clear
              sealant on top. It is a more forgiving, hypoallergenic, and
              friendly alternative for nails that need a little extra help with
              strengthening or polish longevity.
              <br />
              <b>*remove of existing powder additional cost</b>
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              Moon Manicure
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              Nails trimmed, shaped, buffed, and conditioned. Cuticles will be
              groomed. Your selection of nail polish or buffing for the perfect
              shine. Includes hand and arm massage.
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              Deluxe Moon Manicure
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              The Moon Manicure plus a clay hand mask, paraffin treatment, and a
              finishing lotion.
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              Manicure Gel Polish
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              Nails trimmed, shaped, buffed, and conditioned. Cuticles will be
              groomed. Your selection of gel nail polish. Includes hand and arm
              massage. Typically lasts 2 to 3 weeks.
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              <b>*remove of existing powder additional cost</b>
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              Color Polish change
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              <Box
                sx={{
                  textAlign: "left",
                  margin: " 0px auto",
                }}
                width={{ xs: "100%", md: 500 }}
                padding={{ xs: "15px", md: "none" }}
              >
                <div className={classes.priceLine}>
                  <span>
                    <b> Hands</b>
                  </span>
                  <span></span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    {" "}
                    <b>Feets</b>
                  </span>
                  <span></span>
                </div>
              </Box>
              <b>*French design, Nail Art or Color Tip additional cost</b>
            </Typography>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
