import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useStyles from "./styles.js";
import "./styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import SocialMedia from "../socialMedia/socialMedia.js";
import newmoonwallpaper from "../../assets/moonwallpaper.jpg";
import newmoon2 from "../../assets/newmoon2.jpg";
import newmoon3 from "../../assets/newmoon3.jpg";
import newmoon4 from "../../assets/newmoon4.jpg";
import newmoon5 from "../../assets/newmoon5.jpg";

export default function HomePage() {
  const classes = useStyles();
  return (
    <Box sx={{ width: "100%" }}>
      <Typography
        variant="h3"
        component="div"
        gutterBottom
        textAlign="center"
        fontFamily="Nanum Myeongjo, serif;"
      >
        Welcome to New Moon Nail & Spa
        <br />
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        fontFamily="Nanum Myeongjo, serif;"
        textAlign={"center"}
      >
        We are conveniently located right next to the DMV in Hillsboro, Oregon.
      </Typography>

      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        autoPlay={true}
        infiniteLoop={true}
        interval={5000}
        showIndicators={false}
      >
        <div>
          <img class="slideShowImg" src={newmoonwallpaper} alt="nails" />
        </div>
        <div>
          <img class="slideShowImg" src={newmoon2} alt="nails" />
        </div>
        <div>
          <img class="slideShowImg" src={newmoon3} alt="nails" />
        </div>
        <div>
          <img class="slideShowImg" src={newmoon4} alt="nails" />
        </div>
        <div>
          <img class="slideShowImg" src={newmoon5} alt="nails" />
        </div>
      </Carousel>

      <Typography
        variant="h4"
        gutterBottom
        component="div"
        textAlign="center"
        paddingTop="30px"
        fontFamily="Nanum Myeongjo, serif;"
      >
        About Us
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        fontFamily="Nanum Myeongjo, serif;"
      >
        We are a Vietnamese-American owned Nail & Spa. New Moon comes from our
        owner's Vietnamese name which means moon. The New Moon is the start of a
        lunar cycle and we believe in refreshing yourself at our nail salon
        every time you want to give yourself some self-care. A rejuvenating
        environment for ladies and gentlemen to have a nail & spa day. New Moon
        Nails and Spa specializes in nail care services such as manicures,
        pedicures, dipping Powder, waxing and nail enhancements.
        <br />
        <br />
        Safety and Sanitation is important to us here at New Moon Nails & Spa.
        We have implemented processes that medically sterilize and disinfect
        tools after each use. Our technicians only use the top-rated sanitary
        spa equipment along with vigorous staff training and service procedures.
      </Typography>
      <SocialMedia />
    </Box>
  );
}
