import * as React from "react";
import Box from "@mui/material/Box";
import SimpleMap from "../googleMap/googleMap";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useStyles from "./styles.js";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  backgroundColor: "#FAF6F2",
  boxShadow: "none",
  display: "flex",
  marginBottom: "30px",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export default function ContactUs() {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} backgroundColor="none" boxShadow="none">
          <Item>
            {" "}
            <Box
              sx={{
                width: 300,

                textAlign: "left",
                margin: " 0px auto",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                fontFamily="Nanum Myeongjo, serif;"
                fontWeight="900"
              >
                Hours
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="div"
                fontFamily="Nanum Myeongjo, serif;"
              >
                Sunday: 11am - 5pm
                <br />
                Monday: 10am - 7pm
                <br />
                Tuesday: 10am - 7pm
                <br />
                Wednesday: 10am - 7pm
                <br />
                Thursday: 10am - 7pm
                <br />
                Friday: 10am - 7pm
                <br />
                Saturday: 10pm - 6pm
              </Typography>
            </Box>
            <Box
              sx={{
                width: 300,
                height: 200,
                textAlign: "left",
                margin: " 15px auto",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                fontFamily="Nanum Myeongjo, serif;"
                fontWeight="900"
              >
                Location
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="div"
                fontFamily="Nanum Myeongjo, serif;"
              >
                1300 SW Oak St E,
                <br />
                Hillsboro, OR 97123
              </Typography>

              <Typography
                variant="h5"
                gutterBottom
                component="div"
                fontFamily="Nanum Myeongjo, serif;"
                fontWeight="900"
              >
                Contact Us
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="div"
                fontFamily="Nanum Myeongjo, serif;"
              >
                (503) 640-9818
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="div"
                fontFamily="Nanum Myeongjo, serif;"
              >
                info@newmoonnailsspa.com
              </Typography>
            </Box>
          </Item>
        </Grid>
      </Grid>

      <Box
        sx={{
          width: "100%",
          height: 458,
          backgroundColor: "primary.dark",
          "&:hover": {
            backgroundColor: "primary.main",
            opacity: [0.9, 0.8, 0.7],
          },
        }}
      >
        <SimpleMap />
        <a href="https://icons8.com/icon/PZTTDl8ML4vy/location-pin">
          Location Pin icon by Icons8
        </a>
      </Box>
    </>
  );
}
