import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useStyles from "./styles.js";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Wax() {
  const classes = useStyles();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              Women & Gentlemen Wax
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              <Box
                sx={{
                  textAlign: "left",
                  margin: " 0px auto",
                }}
                width={{ xs: "100%", md: 500 }}
                padding={{ xs: "15px", md: "none" }}
              >
                <br />

                <div className={classes.priceLine}>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}>
                      Upper Lip
                    </b>
                  </span>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}></b>
                  </span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}>Chin</b>
                  </span>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}></b>
                  </span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}>
                      Eyebrow
                    </b>
                  </span>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}></b>
                  </span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}>
                      Underarm
                    </b>
                  </span>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}></b>
                  </span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}>
                      Facial Waxing
                    </b>
                  </span>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}></b>
                  </span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}>
                      Half Leg
                    </b>
                  </span>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}></b>
                  </span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}>
                      Full Leg
                    </b>
                  </span>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}></b>
                  </span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}>
                      Half Arm
                    </b>
                  </span>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}></b>
                  </span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}>
                      Full Arm
                    </b>
                  </span>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}></b>
                  </span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}>
                      Full Arm
                    </b>
                  </span>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}></b>
                  </span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}>
                      Bikini
                    </b>
                  </span>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}></b>
                  </span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}>
                      Brazilian
                    </b>
                  </span>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}></b>
                  </span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}>
                      Back Wax
                    </b>
                  </span>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}></b>
                  </span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}>
                      Chest Wax
                    </b>
                  </span>
                  <span>
                    <b style={{ fontFamily: "Nanum Myeongjo, serif;" }}></b>
                  </span>
                </div>
              </Box>
            </Typography>
          </Item>
        </Grid>

        <Grid item xs={12}>
          <Item>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              Facial
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              Experience a dramatic difference in the quality and texture of
              your skin, a deep cleaning and exfoliation with steam,
              extractions, massage, masque and moisturizer. Mask is applied to
              skin to improve the circulation, which revitalize, rejuvenate, and
              refresh your face. Makes you feel younger looking. Also, includes
              hands and arm massage, which will make you feel refreshed and
              relaxed.
              <Box
                sx={{
                  textAlign: "left",
                  margin: " 0px auto",
                }}
                width={{ xs: "100%", md: 500 }}
                padding={{ xs: "15px", md: "none" }}
              >
                <div className={classes.priceLine}>
                  <span> </span>
                  <span></span>
                </div>
              </Box>
            </Typography>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
