import * as React from "react";
import Box from "@mui/material/Box";
import { green } from "@mui/material/colors";
import Icon from "@mui/material/Icon";
import FacebookIcon from "@mui/icons-material/Facebook";
import Link from "@mui/material/Link";
import InstagramIcon from "@mui/icons-material/Instagram";
import Typography from "@mui/material/Typography";
// const preventDefault = (event) => event.preventDefault();
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import useStyles from "./styles.js";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
export default function Links() {
  const classes = useStyles();
  return (
    <Box
      sx={{
        typography: "body1",
        "& > :not(style) + :not(style)": {
          ml: 2,
        },
      }}
      //   onClick={preventDefault}
    >
      {" "}
      <Grid item xs={12} textAlign="center" className={classes.iconBox}>
        <Typography
          variant="h6"
          component="div"
          fontFamily="Nanum Myeongjo, serif;"
        >
          Follow Us:
        </Typography>
        <Link href="https://www.facebook.com/newmoonnailsandspa">
          <FacebookIcon
            className={classes.socialIcon}
            style={{ fontSize: "50px" }}
          />
        </Link>
        <Link href="https://www.instagram.com/newmoonnailsandspa/">
          <InstagramIcon
            className={classes.socialIcon}
            style={{ fontSize: "50px" }}
          />
        </Link>
      </Grid>
    </Box>
  );
}
