import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useStyles from "./styles.js";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Pedicure() {
  const classes = new useStyles();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              Moon Pedicure
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              Nails trimmed, shaped, buffed, and conditioned with
              callus-smoother treatment. Cuticles will be groomed.
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              Meteor Pedicure
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              The Moon Pedicure with hot stones placed on specific muscles and
              pressure points to release toxins, and enhance blood circulation.
              Includes a paraffin treatment that treats severely dry, rough, and
              flaky skin, relaxing full leg and foot massage, and your selection
              of nail polish.
            </Typography>
          </Item>
        </Grid>

        <Grid item xs={12}>
          <Item>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              An Organic Experience
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              Experience relaxation in the luxurious organic spa with one
              perfect system for your hands and feet.
              <br />
              <br />
              The Spa Starter Kit comes in 6 scents!
              <br />
              <Box
                sx={{
                  textAlign: "left",
                  margin: " 0px auto",
                }}
                width={{ xs: "100%", md: 500 }}
                padding={{ xs: "15px", md: "none" }}
              >
                <div className={classes.priceLine}>
                  <span>
                    <b style={{ color: "#a4e333" }}>1.Cucumber Fresh</b>
                  </span>
                  <span></span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b style={{ color: "#9300ff" }}>2.Lavender Relieve</b>
                  </span>
                  <span></span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b style={{ color: "#77B41B" }}>3.Green Tea Detox</b>
                  </span>
                  <span></span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b style={{ color: "#fc3468" }}>4.Jasmine Soothe</b>
                  </span>
                  <span></span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b style={{ color: "#ff6600" }}>5.Collagen Sweet Orange</b>
                  </span>
                  <span></span>
                </div>
                <div className={classes.priceLine}>
                  <span>
                    <b style={{ color: "#c175ff" }}>
                      6.Collagen Lavender & Lace
                    </b>
                  </span>
                  <span></span>
                </div>
              </Box>
              Spa Comes in 6 Steps
              <Box
                sx={{
                  width: "100%",

                  textAlign: "left",
                  margin: " 0px auto",
                  padding: "15px",
                }}
              >
                <b>1.Dead sea salt soak.</b> (A natural detoxifying sea salt
                process that cleanses the hand and feet, leaving the skin
                feeling purified and hydrated.)
                <br />
                <b>2.Callus Exfoliator</b> (A revolutionary formula created to
                safely, gently, and deeply exfoliate calluses or prone skin.)
                <br />
                <b>3.Sugar Scrub</b> (A granulated sugar process to help
                exfoliate dead skin, leaving the skin smoother, replenished, and
                glowing.)
                <br />
                <b>4.Moisture Mask</b> (A natural hydration mask that
                replenishes, conditions, heals, and protects the skin.)
                <br />
                <b>5.Massage Cream</b> (A massaging process with a refreshing,
                smoothing, and hydrating cream for the skin.)
                <br />
                <b>6.Paraffin Wax </b> (A warm liquid wax that helps eliminate
                dead skin, moisturizes, and increases blood circulation to
                achieve soft and radiant skin.)
                <br />
              </Box>
            </Typography>
          </Item>
        </Grid>

        <Grid item xs={12}>
          <Item>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              color="black"
              fontFamily="Nanum Myeongjo, serif;"
            >
              <b>
                *additional cost to any price above for Gel or Shellac Service
                <br />
                Take off Gel or Shellac Polish with Service additional cost
              </b>
            </Typography>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
