import * as React from "react";
import "./footer.css";

export default function Footer() {
  const getFullYear = () => {
    return new Date().getFullYear();
  };
  return (
    <>
      <footer>
        <div class="footer">
          <p class="copyRightText">
            Copyright &copy; New Moon Nail & Spa {getFullYear()}
          </p>
        </div>
      </footer>
    </>
  );
}
