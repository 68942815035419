import React, { Component } from "react";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => (
  <div
    style={{
      color: "black",

      padding: "15px 10px",
      display: "inline-flex",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      transform: "translate(-50%, -50%)",
    }}
  >
    <img src="https://img.icons8.com/color/48/000000/marker--v2.png" />
    {text}
  </div>
);

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 45.518728175707935,
      lng: -123.00430010356403,
    },
    zoom: 17,
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: "100%", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyB53gDIZTY2bpTqPiHT5XPxgTFJ_NnSBaw" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={45.518728175707935}
            lng={-123.00430010356403}
            text="New Moon Nails & Spa"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
