import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  socialIcon: {
    fontSize: "large",
    margin: "0 auto",
  },
  //   iconBox: {
  //     display: "flex",
  //     justifyContent: "space-around",
  //   },
}));
